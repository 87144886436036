<template>
  <div class="view-container">
    <div class="card">
      <div class="card-body">
        <div class="info">
          Мы подготовили простой список, который поможет вам быстрее начать
          работу в системе
        </div>
        <div class="list-container flex flex-column">
          <div class="list-check flex flex-column">
            <div class="check-label flex justify-content-between">
              <router-link to="/company" class="text-link">
                <custom-checkbox
                  :checked="beginProgress.company"
                  :noninteractive="true"
                  label="Заполните информацию о компании"
                />
              </router-link>
              <router-link to="/company" class="btn btn-accent"
                >Перейти</router-link
              >
            </div>
            <div class="check-info">
              Это создаст более привлекательный образ компании и позволит вашим
              сотрудникам убедиться, что все идет как нужно.
            </div>
          </div>
          <div class="list-check flex flex-column">
            <div class="check-label flex justify-content-between">
              <router-link to="/staff" class="text-link">
                <custom-checkbox
                  :checked="beginProgress.staff"
                  :noninteractive="true"
                  label="Создайте сотрудника"
                />
              </router-link>
              <router-link to="/staff" class="btn btn-accent"
                >Перейти</router-link
              >
            </div>
            <div class="check-info">
              Сотрудник - это человек, который будет проходить обучение. Вы
              можете пропустить этот шаг, если хотите протестировать систему
              самостоятельно.
            </div>
          </div>
          <div class="list-check flex flex-column">
            <div class="check-label flex justify-content-between">
              <router-link to="/group/edit" class="text-link">
                <custom-checkbox
                  :checked="beginProgress.groups"
                  :noninteractive="true"
                  label="Создайте группу сотрудников"
                />
              </router-link>
              <router-link to="/group/edit" class="btn btn-accent"
                >Перейти</router-link
              >
            </div>
            <div class="check-info">
              Группы нужны, чтобы управлять курсами и тестированиями для
              сотрудников. Сначала распределите тестирования или курсы по
              группам, а потом назначьте их сотрудникам. При создании группы
              можно сразу добавить туда сотрудника или самого себя. Человек,
              которого вы выберете куратором, будет иметь те же права на группу,
              что и вы.
            </div>
          </div>
          <div class="list-check flex flex-column">
            <div class="check-label flex justify-content-between">
              <a
                :href="`${siteUrl}/products/all`"
                class="text-link absolute"
                target="_blank"
              >
              </a>
              <custom-checkbox
                :checked="beginProgress.passings_or_tests"
                :noninteractive="true"
                label="Купите или получите тестовые прохождения у менеджера"
              />
              <a
                :href="`${siteUrl}/products/all`"
                class="btn btn-accent"
                target="_blank"
                >Перейти</a
              >
            </div>
            <div class="check-info">
              При успешной покупке прохождения сразу появятся в личном кабинете.
            </div>
          </div>
          <div class="list-check flex flex-column"><div class="check-info">
              Кликните на "Выдать прохождения" в интерфейсе групп. Далее
              выберите, что вы хотите распределить: тестирования или курсы, их
              количество, а также галочками отметьте группу, в которую хотите
              все распределить.
            </div>
          </div>
          <div class="list-check flex flex-column">
            <div class="check-info">
              Действуйте по аналогии с выдачей в группы. Как только сотрудники
              получат тестирования и курсы - они сразу же смогут приступить к их
              прохождению. Результаты вы будете видеть в группе.
            </div>
          </div>
        </div>
        <!-- <div class="table-preloader" v-else>
          <preloader class=""></preloader>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useStore } from "vuex";
import CustomCheckbox from "../../components/Forms/Fields/CustomCheckbox.vue";
import Preloader from "../../components/Technical/Preloader.vue";
export default {
  components: { CustomCheckbox, Preloader },
  name: "begin-work",
  setup() {
    const store = useStore();
    const siteUrl = process.env.VUE_APP_SITE;

    const beginProgress = computed(() => store.state.beginProgress);

    onBeforeMount(() => {
      store.dispatch("getBeginWork");
    });

    return { siteUrl, beginProgress };
  },
};
</script>

<style scoped lang="scss">
.info {
  font-weight: 500;
  font-size: 1.1rem;
  margin: 15px 0;
}
.list-container {
  padding: 15px 35px;
  margin: 25px 0;
  gap: 15px;

  @media (max-width: 1440px) {
    padding: 15px 0;
    margin: 8px 0;
  }
}

.check-label {
  padding: 20px 0 15px 0;
  border-top: 1px solid var(--cream-color);
  border-bottom: 1px solid var(--cream-color);
  align-items: center;
  position: relative;
  @media (max-width: 1440px) {
    padding: 15px 0 10px 0;
  }
}

.text-link {
  flex: 1;
  padding-right: 10px;
  &.absolute {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 45px;
  }
}

.check-info {
  margin-top: 15px;
  padding: 0 15px;
  font-size: 0.95rem;
}

.form-group {
  font-size: 1rem;
}
</style>